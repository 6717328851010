namespace OppHonpoCommon {
} 

module OppHonpo {
    declare var pConf;
    declare var undisplayFlash: any;
    declare var displayFlash: any;
    
    jQuery(function ($) {
        var alist = jQuery("a[href^='https://opp-honpo.ocnk.net/contact']");
        alist.attr("href", '/page/83?from=top');


        var alist2 = jQuery("a[href^='https://www.opp-honpo.net/contact']");
        alist2.attr("href", '/page/83?from=top');

        var forms = jQuery("form[action^='https://opp-honpo.ocnk.net/contact']");
        forms.attr("action", '/page/83');

        var contactInput = jQuery("input[value='お問い合わせ']");
        //contactInput.attr("onClick", 'OppHonpo.jumpContact()');
        contactInput.click(() =>
            location.replace('/page/83?from=product')
        );

        jQuery("form[action='https://www.opp-honpo.net/product-list']").submit(function () {
            event.preventDefault();

            var form = jQuery(this);
            var sk = form.find("input[name=keyword]").val();
            var url1 = "https://www.workup-system.com/Api/SearchKey/ReplaceSearchKey?key=" + encodeURIComponent(sk);
            jQuery.ajax({
                type: "GET",
                url: url1,
                dataType: "json",
                async: false,
            }).then(function (src) {
                window.location.href = "https://www.opp-honpo.net/product-list?keyword=" + encodeURIComponent(src);
            });
            var url = "https://www.workup-system.com/api/SearchKey/RegSearchKey?siteInfoId=3&key=" + encodeURIComponent(sk);
            jQuery.get(url);
        });

        //サンプル送信用の履歴作成
        try {
            var jsonStr = (<any>jQuery).cookie("productLog");
            var array = new Array();
            if (jsonStr != null) {
                try {
                    array = JSON.parse(jsonStr);
                }
                catch (e) {

                }
            }
            if (array.length == 0 || array[array.length - 1] != pConf.productId) {
                array.push(pConf.productId);
                jsonStr = JSON.stringify(array);
                (<any>jQuery).cookie("productLog", jsonStr, { path: '/' });

            }
        }
        catch (e) {

        }

        const $select = $('.shipping_date_selection');
        $select.attr('autocomplete', 'off');
        // <option>要素をループしてテキストを変更
        $select.find('option').each(function () {
            const $option = $(this);
            if ($option.text() === '指定なし') {
                $option.text('指定なし(最短)');
            }
        });

        
        setupEstimateButton();
        removeCart1Text();

    });

    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate();
            });
        }
    }

    function toEstimate() {
        const formInnnerHtml = jQuery("#register").html();
        const results = new Array();
        let idx = 0;
        jQuery(".cart_data_box")
            .each(function () {
                results.push(parseProduct(idx, this));
                idx++;
            });

        const query = results.join("&");
        const url = "https://www.workup-system.com/Estimate/Print/?siteId=3&" + query;
        window.open(url);
    }

    function parseProduct(idx, itemDiv) {
        const productName = jQuery(itemDiv).find(".goods_name").text();
        const unitPrice = jQuery(itemDiv).find(".cart_price").find(".price").text().replace("円", "").replace(",", "");
        const selectNum = jQuery(itemDiv).find(".cart_quantity").val();
        const qentity = "[" + idx + "].";
        return qentity + "productName=" + encodeURIComponent(productName) + "&" + qentity + "unitPrice=" + unitPrice + "&" + qentity + "selectNum=" + selectNum;
    }

    function removeCart1Text() {
        if (document.location.href.indexOf("/cart/1") == -1)
            return;
        var parentDiv = document.getElementById("shippingdatetime_box_1");
        if (parentDiv == null)
            return;

        if (parentDiv.childNodes.length < 2)
            return;

        if (parentDiv.childNodes[2].textContent.indexOf("※お届け日はご指定いただけません") == -1)
            return;
        parentDiv.childNodes[2].remove();
    }
}

